const nav = document.querySelector('#nav');
const menu = document.querySelector('#menu');
const menuToggle = document.querySelector('.nav__toggle');
const menuItem = document.querySelectorAll('.nav__item');
let isMenuOpen = false;

// Add shadow on scroll
const header = document.querySelector("header")
let scrolled = false;

var navScrollingToggle = function (event) {
  let scrollpos = window.scrollY;

  if (scrollpos == 0 && scrolled) { scrolled = false; header.classList.toggle('nav--scrolling'); }
  else if (scrollpos > 0 && !scrolled) { scrolled = true; header.classList.toggle('nav--scrolling'); }
};

window.addEventListener('load', navScrollingToggle, false);
window.addEventListener('scroll', navScrollingToggle, false);

// TOGGLE MENU ACTIVE STATE
menuToggle.addEventListener('click', e => {
  e.preventDefault();
  isMenuOpen = !isMenuOpen;

  // toggle a11y attributes and active class
  menuToggle.setAttribute('aria-expanded', String(isMenuOpen));
  menu.hidden = !isMenuOpen;
  nav.classList.toggle('nav--open');
  document.body.classList.toggle("overflow-hidden");
});

// CLOSE NAV ON CLICK
menuItem.forEach(item =>
  item.addEventListener("click", () => {
    isMenuOpen = false;

    // toggle a11y attributes and active class
    menuToggle.setAttribute('aria-expanded', String(isMenuOpen));
    menu.hidden = !isMenuOpen;
    nav.classList.toggle('nav--open');
    document.body.classList.toggle("overflow-hidden");
  })
);

// TRAP TAB INSIDE NAV WHEN OPEN
nav.addEventListener('keydown', e => {
  // abort if menu isn't open or modifier keys are pressed
  if (!isMenuOpen || e.ctrlKey || e.metaKey || e.altKey) {
    return;
  }

  // listen for tab press and move focus
  // if we're on either end of the navigation
  const menuLinks = menu.querySelectorAll('.nav__link');
  if (e.keyCode === 9) {
    if (e.shiftKey) {
      if (document.activeElement === menuLinks[0]) {
        menuToggle.focus();
        e.preventDefault();
      }
    } else if (document.activeElement === menuToggle) {
      menuLinks[0].focus();
      e.preventDefault();
    }
  }
});
